<template>
  <v-card
    flat
    class="pa-3 mt-1"
  >
    <v-card-title class="d-flex align-center justify-center py-0">
      <div
        class="d-flex align-center"
      >
        <v-img
          :src="require(`@/assets/images/logos/logo.png`)"
          max-width="300px"
          alt="logo"
          contain
          class="me-0 "
        ></v-img>
      </div>
    </v-card-title>
    <v-form class="multi-col-validation">
      <v-card-text class="pt-1">
        <p class="text-h6 text-center">Add an Advertisment {{ resourceKey }} Resource</p>
        <v-row>
          <v-col cols="12" class="py-0 my-1">
            <v-text-field
              v-model="resourceName"
              ref="resource_name"
              outlined dense
              label="Enter Resource Name"
            ></v-text-field>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <v-textarea
              outlined
              v-model="resourceDescription"
              ref="resource_description"
              name="input-7-4"
              label="Enter Resource Description"
            ></v-textarea>
          </v-col>

          <v-col cols="12" class="py-0 my-1">
            <v-file-input
              show-size
              v-model="resourceFile"
              ref="resource_file"
              truncate-length="18"
              style="cursor: pointer"
              label="Choose Resource File"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="text-end">
        <spinner v-if="loading" class="text-end"></spinner>
        <v-btn 
          v-else
          color="primary"
          class="me-3 mt-3"
          @click="addResource()"
        >
          Add {{ resourceKey }}
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    const resource_key = this.$route.params.key  

    return {
      loading: false,
      resourceName: "",
      resourceKey: "",
      resourceFile: [],
      resourceDescription: "",
      // resourceFileTypes: file_types,

      mdiKeyOutline,
      mdiLockOpenOutline,
      mdiEyeOffOutline,
      mdiEyeOutline,
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      this.getResourceType();
      document.title = "Manager: Savings | Add "+ this.resourceKey+" Resource"
  },

  methods:{

    async getResourceType(){
      const resource_key = this.$route.params.key  
      this.resourceKey = resource_key
      console.log(this.resourceKey)
    },

    async addResource(){
      console.log(this.resourceFile)
      if(this.resourceName === ""){
          this.$store.commit('setSnackBarMessage', "Please fill in the resource name")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.resource_name.focus()  // this causes a an auto fucos to the element
      }
      else if(this.resourceDescription === ""){
          this.$store.commit('setSnackBarMessage', "Please input the resource description")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.resource_description.focus()  // this causes a an auto fucos to the element
      }
      else if(!this.resourceFile){
          this.$store.commit('setSnackBarMessage', "Please upload the resource file")
          this.$store.commit('setSnackBarColor', "red darken-4")
          this.$store.commit('activateSnackBar', true)
          this.$refs.resource_file.focus()  // this causes a an auto fucos to the element
      }
      else {
          this.loading = true

          let formData = new FormData()
          formData.append('resourceType', this.resourceKey)
          formData.append('resourceFile', this.resourceFile)
          formData.append('resourceDescription', this.resourceDescription)
          formData.append('resourceName', this.resourceName)
          if(this.$store.state.isSavings){
            formData.append('section', "Savings")
          }
          else if(this.$store.state.isMarketPlace){
            formData.append('section', "Market Place")
          }
          else if(this.$store.state.isNjangi){
            formData.append('section', "Njangi")
          }
          else if(this.$store.state.isFundraising){
            formData.append('section', "Crowd Funding")
          }
          else if(this.$store.state.isMonitor){
            formData.append('section', "Monitor")
          }
      
          await axios
              .post('/api/v1/manager/add/marketing_resource/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
              .then(response => {
                  this.$store.commit('setSnackBarMessage', response.data[0].text)
                  this.$store.commit('setSnackBarColor', response.data[0].color)
                  this.$store.commit('activateSnackBar', true)
                  if(response.data[0].id !== 0){
                      let router = this.$router
                      setTimeout(function(){
                          router.push({ name: 'savings.marketing_resources'})
                      }, 4000)
                  }
              })
              .catch(error => {
                  if (error.response){
                      this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }else{
                      this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                      this.$store.commit('setSnackBarColor', "red darken-4")
                      this.$store.commit('activateSnackBar', true)
                  }
              })

          this.loading = false
      }
    },
  },
}
</script>
